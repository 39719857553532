import {
  centerCustom,
  convertMoneyFormat,
  moneyFormat,
  nameCustom,
  percentDecimalValue,
  percentValue,
} from "../../components/Chart/Format/format";
import getTabulateContractors from "../../services/Contracts/getTabulateContractors";

const useTabulateContractor = () => {
  const formatDataTable = (data) => {
    const formattedData = {
      header: [
        {
          name: "Contratista",
          options: {
            customBodyRender: nameCustom,
          },
        },
        {
          name: "Documento",
          options: {
            customBodyRender: centerCustom,
          },
        },
        "Contratos",
        {
          name: "Valor",
          options: {
            customBodyRender: moneyFormat,
          },
        },
        {
          name: "financial_progress",
          label: "Avance financiero",
          options: {
            customBodyRender: moneyFormat,
          },
        },

        {
          name: "advance",
          label: "Avance físico",
          options: {
            customBodyRender: percentValue,
          },
        },
        {
          name: "% Contratos",
          options: {
            customBodyRender: percentDecimalValue,
          },
        },
        {
          name: "% Valor",
          options: {
            customBodyRender: percentDecimalValue,
          },
        },
      ],
      body: data.map((item) => [
        item.contractor,
        item.document,
        item.contracts,
        Number(item.value),
        item.financial_progress,
        item.advance,
        Number(item.percent_contracts),
        Number(item.percent_value),
      ]),
    };

    return formattedData ?? null;
  };

  function getTopContracts(data) {
    const sortedData = data.sort((a, b) => b.contracts - a.contracts);

    const contractValues = sortedData.map((item) => ({
      y: item.contracts,
      name: item.contractor,
      percent_contracts: item.percent_contracts,
      value: item.value,
      financial_progress: item.financial_progress,
      advance: item.advance,
      percent_value: item.percent_value,
    }));

    return contractValues ?? null;
  }

  const getContractors = async (filter = null) => {
    const response = await getTabulateContractors(filter);
    const result = {
      chart: {},
      table: {},
    };

    if (response.status) {
      let avanceF = 0;
      let avanceFisico = 0;

      response.data.contractors.forEach((element) => {
        if (!isNaN(element.financial_progress)) {
          avanceF = avanceF + element.financial_progress;
        }
        if (!isNaN(element.advance)) {
          avanceFisico = avanceFisico + element.advance;
        }
      });

      avanceF = "$" + avanceF;
      avanceFisico = avanceFisico + "%";

      const contractors = response.data?.contractors;
      const total_contracts = response.data?.total_contracts;
      const total_values = "$" + convertMoneyFormat(response.data?.total_value);

      if (contractors) {
        result.table = formatDataTable(contractors);
        result.table.footer = [
          "",
          "",
          total_contracts,
          total_values,
          avanceF,
          avanceFisico,
          "100%",
          "100%",
        ];
        result.chart = getTopContracts(contractors);
      }
    }

    return result;
  };
  return {
    getContractors,
  };
};

export default useTabulateContractor;
