import Cookies from "js-cookie"

const editarRule = async (datos) => {

    const route = "adm_securitygroup"
    const endpoint = localStorage.getItem("api_" + route) || localStorage.getItem("api")
    const url = `${endpoint}rule/${datos["id"]}`
    const app = localStorage.getItem("application")
    const instance = localStorage.getItem("instance")

    try {
        const config = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get('tkreact_' + instance),
                "application": app
            },
            body: JSON.stringify(datos)
        }

        const response = await fetch(url, config)
        const json = await response.json()
        return json ?? null

    } catch (error) {
        console.log(error)
        return null
    }
}

export default editarRule
