import Cookies from "js-cookie"

const eliminarRule = async (id) => {

    const route = "adm_securitygroup"
    const endpoint = localStorage.getItem("api_" + route) || localStorage.getItem("api")
    const url = `${endpoint}rule/${id}`
    const app = localStorage.getItem("application")
    const instance = localStorage.getItem("instance")

    try {
        const config = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get('tkreact_' + instance),
                "application": app
            }
        }

        const response = await fetch(url, config)
        const json = await response.json()
        return json ?? null
    } catch (error) {
        console.log(error)
        return null
    }
}

export default eliminarRule
