import Cookies from 'js-cookie'

const saveDynamicTable = async (name, fields) => {

    const route = "process"
    const endpoint = localStorage.getItem("api_" + route)
    const id = localStorage.getItem("id")
    const url = `${endpoint}admDynamicTable`
    const instance = localStorage.getItem("instance")

    try {
        const config = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get('tkreact_' + instance)
            },
            body: JSON.stringify({
                id: id,
                name: name,
                fields: fields
            })
        }

        const response = await fetch(url, config)
        return await response.json()

    } catch (error) {
        return null
    }
}

export default saveDynamicTable
