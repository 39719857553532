import Cookies from 'js-cookie'

const saveLayout = async (body, secureid = null, securemd = null) => {

    const route = "layout"
    const endpoint = localStorage.getItem("api_" + route) || localStorage.getItem("api")
    const instance = localStorage.getItem("instance")
    const id = secureid ?? localStorage.getItem("id")
    const md = securemd ?? localStorage.getItem("md")
    const url = `${endpoint}layout`

    const data = { ...body }
    data.id = id
    data.md = md

    try {
        const config = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get('tkreact_' + instance)
            },
            body: JSON.stringify(data)
        }

        const response = await fetch(url, config)
        return await response.json()

    } catch (error) {
        console.log(error)
        return null
    }
}

export default saveLayout
