import Cookies from 'js-cookie'

const activar = async (date) => {

    const route = "instancia"
    const endpoint = localStorage.getItem("api_" + route) || localStorage.getItem("api")
    const instance = localStorage.getItem("instance")
    const url = endpoint + "validate"

    let body = {}

    if (date === null) {
        body = {
            "operacion": "activar"
        }
    } else {
        body = {
            "operacion": "activar",
            "fecha_inactivar": date
        }
    }

    try {
        const config = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get('tkreact_' + instance)
            },
            body: JSON.stringify(body)
        }

        const response = await fetch(url, config)
        const json = await response.json()
        return json

    } catch (error) {
        console.log(error)
    }
}

export default activar