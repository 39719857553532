import Cookies from 'js-cookie'

const getDynamicTableConf = async () => {

    const route = "process"
    const endpoint = localStorage.getItem("api_" + route)
    const id = localStorage.getItem("id")
    const url = `${endpoint}dynamicTables/${id}`
    const instance = localStorage.getItem("instance")

    try {
        const config = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get('tkreact_' + instance)
            }
        }

        const response = await fetch(url, config)
        return await response.json()

    } catch (error) {
        return null
    }
}

export default getDynamicTableConf
