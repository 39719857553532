import Cookies from "js-cookie";

const getDynamicTableData = async (tableId, filter) => {
  const route = "process";
  const endpoint = localStorage.getItem("api_" + route);
  const id = localStorage.getItem("id");
  const url = endpoint + "dynamicTable";
  const instance = localStorage.getItem("instance");

  try {
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("tkreact_" + instance),
      },
      body: JSON.stringify({
        id: id,
        dynamicTableId: tableId,
        filter: filter,
      }),
    };

    const response = await fetch(url, config);
    console.log(await response.json());
    return await response.json();
  } catch (error) {
    return null;
  }
};

export default getDynamicTableData;
