import { Fragment } from "react"
import FilterContract from "../FilterContract"
import BasicTable from "../../../components/Table/BasicTable"
import BarColumnChart from "../../../components/Chart/BarColumn"
import Loading from "../../../components/Loading/Loading"
import useContractLogic from "./useContractsLogic"
import NotData from "../../../components/Chart/NoData/NotData"

function isEmptyDict(obj) {
    if (obj) {
        return Object.keys(obj).length === 0
    }

    return true
}

const Contracts = () => {

    const {
        statesData,
        loading,
        filter
    } = useContractLogic()

    if (loading || !statesData.table) {
        return <Loading />
    }

    return (
        <Fragment>
            <FilterContract filter={filter} />

            {
                isEmptyDict(statesData?.table) && (
                    <NotData />
                )
            }

            {
                !isEmptyDict(statesData?.table) && (
                    <div>
                        <BarColumnChart
                            title="Contratos por estado"
                            data={statesData.chart}
                            viewTable={false}
                            columsOptions={[
                                {
                                    label: "Estado",
                                    value: "name"
                                },
                                {
                                    label: "Cantidad",
                                    value: "y"
                                },
                                {
                                    label: "%",
                                    value: "percent"
                                }
                            ]}
                            defaultType="pie"
                        />

                        <BasicTable
                            columns={statesData.table.header}
                            data={statesData.table.body}
                            Footer={statesData.table.footer}
                        />
                    </div>
                )
            }
        </Fragment>
    )
}

export default Contracts
