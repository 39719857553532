import { useEffect, useState } from "react"
import jwt_verification from "./jwt_verification"
import Cookies from 'js-cookie'
import validate from "../services/validate"

const useValidarEntorno = () => {
    const [token,setToken] = useState("")
    const [validacion, setValidacion] = useState(null)
    const [status,setStatus] = useState(null)
    const [message,setMessage] = useState("")

    useEffect(() => {

        setToken(Cookies.get("tkreact"))

        if (jwt_verification(token) && localStorage.getItem("instance") !== undefined) {
            setValidacion(true)

            validate().then((result) => {
                setStatus(result.status)
                setMessage(result.message.estado)
            })

        } else {
            setValidacion(false)
        }
    }, [token])

    return {validacion,status,message}
}

export default useValidarEntorno