import { useEffect, useState } from "react"
import { useAppContext } from "../layout"
import useContractsStates from "../../../hooks/Contracts/useContractsStates"
import { errorDialog } from "../../../components/Alert/Alert"
import { checkStartEnd, checkStates, transformData } from "../DynamicTable/hook/utils/validateFilter"


const useContractLogic = () => {

    const { getStates } = useContractsStates()
    const store = useAppContext()

    const [statesData, setStatesData] = useState({
        table: null,
        chart: null
    })

    const [loading, setLoading] = useState(false)

    const filter = async (values = null) => {
        setLoading(true)
        if (values || store.filterValues) {

            const statuscheckstartend = checkStartEnd(values || store.filterValues)

            if (!checkStates(values || store.filterValues)) {
                errorDialog("Debe seleccionar por lo menos un estado")
            } else if (statuscheckstartend !== 'true') {
                errorDialog(statuscheckstartend)
            } else {
                const dataFilter = transformData(values || store.filterValues)
                const states = await getStates(dataFilter.filter)
                setStatesData({ ...states })

                if (store.open) {
                    store.handleClose()
                }
            }
        }
        setLoading(false)
    }

    useEffect(() => {
        if (store.filterValues && store?.fields.length) {
            filter()
        }
    }, [store.fields])

    return {
        statesData,
        loading,
        filter
    }
}

export default useContractLogic
