import Cookies from 'js-cookie'

const getOptionsFilter = async () => {
    const route = "process"
    const endpoint = localStorage.getItem("api_" + route) || localStorage.getItem("api")
    const id = localStorage.getItem("id")
    const url = `${endpoint}config/${id}`
    const instance = localStorage.getItem("instance")

    try {
        const config = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get('tkreact_' + instance)
            }
        }

        const response = await fetch(url, config)
        const json = await response.json()

        return json ?? undefined

    } catch (error) {
        console.log(error)
        return null
    }
}

export default getOptionsFilter
